<template>
  <div class="page-user-choserole">
    <div class="my-page">
      <div class="top">
        <p>选择登录用户</p>
        <div>请根据您的个人信息，在以下选项中选择一个角色登录</div>
      </div>
      <div class="role-box">
        <div
          class="role1"
          :class="{ active: roleType === 2 }"
          @click="roleType = 2"
        >
          <div class="left">
            <p>我是老师</p>
            <div>勤于施教，安于乐教</div>
          </div>
          <div class="right"></div>
        </div>
        <div
          class="role2"
          :class="{ active: roleType === 3 }"
          @click="roleType = 3"
        >
          <div class="left">
            <p>我是家长</p>
            <div>良师益友，陪伴成长</div>
          </div>
          <div class="right"></div>
        </div>
        <!-- 			<div class="role3" :class="{active: roleType === 4}" @click="roleType=4">
					<div class="left">
						<p>我是供应商</p>
						<div>高效处理，便捷服务</div>
					</div>
					<div class="right"></div>
				</div> -->
      </div>
      <van-button type="primary" block color="#47AFA7" @click="next()"
        >下一步</van-button
      >

      <!-- 弹窗 -->
    </div>
  </div>
</template>

<script>
import { Button, Dialog } from "vant";
export default {
  components: {
    [Button.name]: Button,
    [Dialog.name]: Dialog,
  },
  data() {
    return {
      roleType: 2,
      changeRoleInfo: JSON.parse(window.localStorage.getItem("wxuserInfo")),
    };
  },
  mounted() {
    //this.getUser()
  },
  methods: {
    next() {
      // 判断用户选择
      if (this.roleType == 3) {
        this._getAuditStateInfo();
      } else {
        this.$router.push({
          path: "/Login",
          query: {
            roleType: this.roleType,
          },
        });
      }
    },
    // 获取状态信息
    _getAuditStateInfo() {
      window.localStorage.setItem("IsAdministration", false);
      this.$axios
        .post("/api/WeChat/IdentityLogin", {
          OpenID: this.changeRoleInfo.OpenID,
          UserType: 3,
          headUrl: this.changeRoleInfo.headUrl,
        })
        .then((res) => {				
          // 非初次注册
          if (res.data.IsLocalUser) {
             let userInfo = res.data.loginUser;
              // 存储用户信息
              // 存储Token
              window.localStorage.setItem("TOKEN", userInfo.Token);
            // 弹窗显示未通过信息
            if (res.data.AuditState == 4) {
              Dialog.alert({
                title: "",
                message: res.msg + "(" + res.data.AuditDesc + ")",
                confirmButtonText: "编辑",
                showCancelButton: true,
                confirmButtonColor: "rgb(253, 229, 0)",
              }).then(() => {
                // 审核中
                console.log("5");
                // 跳转到注册编辑页面
                this.$router.push({
                  path: "/RegisterEdit",
                  query: {
                    ID: res.data.ID,
                  },
                });
              });
            } else if (res.data.AuditState == 2 || res.data.AuditState == 1) {
              Dialog.alert({
                title: "",
                message: res.msg,
                confirmButtonText: "关闭",
                confirmButtonColor: "rgb(253, 229, 0)",
              }).then(() => {
                // on close
              });
            } else {
              //审核通过
              let userInfo = res.data.loginUser;
              // 存储用户信息
              // 存储Token
              window.localStorage.setItem("TOKEN", userInfo.Token);
              // 存储UserType 1 学生，2职工，3家长
              window.localStorage.setItem("UserType", userInfo.UserType);
              // 存储Token
              window.localStorage.setItem("SchoolID", userInfo.ReturnSchoolID);
              // 存储用户信息
              window.localStorage.setItem("userInfo", JSON.stringify(userInfo));
              this.$router.push("/Home");
            }
          } else {
            // 初次注册
            this.$router.push("/ParentRegister");
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
